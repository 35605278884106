import React, { useState, useEffect } from 'react';
import { Container, Grid, Card, CardContent, Typography, Box, CircularProgress, Switch, FormControlLabel } from '@mui/material';
import { useParams } from 'react-router-dom';
import { getDJsByLocation, updateDJRecord } from '../api/airtable';

const AdminView = () => {
  const { id } = useParams();
  const [djs, setDjs] = useState([]);
  const [error, setError] = useState(null);
  const [loadingDJInfo, setLoadingDJInfo] = useState(true);

  useEffect(() => {
    const fetchDJs = async () => {
      try {
        const djRecords = await getDJsByLocation(id);
        setDjs(djRecords);
        setLoadingDJInfo(false);
      } catch (error) {
        console.error('Error fetching DJs:', error);
        setError('Hubo un error al cargar los DJs. Por favor, inténtelo de nuevo.');
        setLoadingDJInfo(false);
      }
    };

    fetchDJs();
  }, [id]);

  const handleToggleActive = async (dj) => {
    try {
      const updatePromises = djs.map(djRecord => {
        return updateDJRecord(djRecord.id, { 'ID Form': '' });
      });
      
      await Promise.all(updatePromises);
      const newIDForm = `f${id}m`;
      await updateDJRecord(dj.id, { 'ID Form': newIDForm });
      const updatedDJRecords = await getDJsByLocation(id);
      setDjs(updatedDJRecords);
    } catch (error) {
      console.error('Error updating DJ:', error);
      setError('Hubo un error al actualizar el DJ. Por favor, inténtelo de nuevo.');
    }
  };

  return (
    <Box sx={{ minHeight: '100vh', backgroundColor: '#111', color: '#fff', py: 2 }}>
      <Container maxWidth="md">
        <Typography variant="h5" fontWeight={700} align="center" gutterBottom mb={4}>
          ¿Cual DJ quieres activo?
        </Typography>
        <Grid container spacing={4}>
          {djs.map((dj, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{ 
                backgroundColor: '#222', 
                color: '#fff', 
                borderRadius: 8, 
                height: '350px', 
                display: 'flex', 
                flexDirection: 'column', 
                justifyContent: 'space-between',
                boxShadow: dj.fields['ID Form'] ? '0 0 15px #4caf50' : '0 0 10px #333'
              }}>
                <CardContent>
                  {loadingDJInfo ? (
                    <CircularProgress color="secondary" />
                  ) : (
                    <>
                      {dj.fields['Foto'] && dj.fields['Foto'].length > 0 ? (
                        <img 
                          src={dj.fields['Foto'][0].url} 
                          alt={dj.fields['Name']} 
                          style={{ width: '100%', height: '200px', objectFit: 'cover', borderRadius: 10 }} 
                        />
                      ) : (
                        <Typography variant="h6" color="error">No se pudo cargar la imagen del DJ</Typography>
                      )}
                      <Typography variant="h6" component="h2" sx={{ marginTop: 2 }}>
                        {dj.fields['Name']}
                      </Typography>
                    </>
                  )}
                </CardContent>
                <Box sx={{ p: 3, mt:-2 }}>
                  <FormControlLabel
                    control={
                      <Switch 
                        checked={!!dj.fields['ID Form']} 
                        onChange={() => handleToggleActive(dj)} 
                        color="success" 
                      />
                    }
                    label={dj.fields['ID Form'] ? "DJ Activo" : "DJ Inactivo"}
                    labelPlacement="start"
                    sx={{ justifyContent: 'space-between', width: '100%' }}
                  />
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
        {error && (
          <Box textAlign="center" mt={4}>
            <Typography variant="h6" color="error">
              {error}
            </Typography>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default AdminView;
